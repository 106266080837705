// @flow
import { AuthActionTypes, ProductsTypes } from '../../redux/services/constants';

const initialState = {
    proListed: false,
    productsList: []
};

const Products = (state = initialState, action) => {

    //console.log("Product Data PROMPTTTTTTT : " + JSON.stringify(action.payload.productsList))
    console.log("Product Action Type : " + action.type)
    //console.log("length is : " + action.payload.length)

    switch (action.type) {
        case ProductsTypes.RECEIVE_PRODUCTS:
            //return [...state, ...action.payload]; // if single items then use '[]'
            /*return {
                ...state,
                productsList: action.payload
            }*/

            // if multiple items then use '{}'
            return {
                proListed: action.payload.proListed,
                productsList: action.payload.productsList,
            }            
            
        case ProductsTypes.FETCH_SINGLE_PRODUCT:
            if (state.products.findIndex(product => product.id === action.productId) !== -1) {
                const singleItem = state.products.filter(product => product.id === action.productId)
                return {
                    ...state,
                    product_details: singleItem
                }
            }

        default:
            return state;
    }
};

export default Products;
