// @flow
import { AuthActionTypes } from '../../redux/services/constants';

const initialState = {};

const Productsmore = (state = [], action) => {    

    //console.log("Product Data Mine : " + JSON.stringify(action.payload))
    //console.log("Product Action Type : " + action.type)
    //console.log("length is : " + action.payload.length)

    switch (action.type) {
        case AuthActionTypes.RECEIVE_PRODUCTS:
            return [...state, ...action.payload];
            
        case AuthActionTypes.FETCH_SINGLE_PRODUCT:
            if (state.products.findIndex(product => product.id === action.productId) !== -1) {
                const singleItem = state.products.filter(product => product.id === action.productId)
                return {
                    ...state,
                    product_details: singleItem
                }
            }

        default:
            return state;
    }
};

export default Productsmore;
