import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import './i18n';

import App from './App';

import { Provider } from 'react-redux';
//import { configureStore } from './redux/store';

import store from './redux/store';
//import { getAllProducts } from './redux/services/actions';
//import { getAllProductsMore } from './redux/services/actions';

class Root extends Component {    

    render() {
        //store.dispatch(getAllProducts());
        //store.dispatch(getAllProductsMore());
        return (
            <Provider store={store}>
                <App />
            </Provider>
        )
    }
}

ReactDOM.render(<Root />, document.getElementById("root"));

//store.dispatch(getAllProducts());

/*class Root extends Component {

    render() {
        //store.dispatch(getAllProducts());
        return (
            <Provider store={store({})}>
                <App />
            </Provider>
        )
    }
}*/



//store.dispatch(getAllProducts());

/*ReactDOM.render(
    
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);*/
