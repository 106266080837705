import { createStore,applyMiddleware,compose } from 'redux';
import thunk from 'redux-thunk';

//import reducers from './reducers';
//import rootReducer from './reducers';
import rootReducer from './root_reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

 const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

export default store;

/*import { createStore, applyMiddleware, compose } from "redux";
import reducers from './reducers';
import createSagaMiddleware from "redux-saga";
import rootSaga from './sagas';
const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducers, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);

export default store;*/

/////////////////////////////////////

// @flow
/*import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

export function configureStore(initialState: {}): any {
    // const composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'];
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(reducers, initialState, composeEnhancers(applyMiddleware(...middlewares)));
    sagaMiddleware.run(rootSaga);
    return store;
}*/

