// @flow
import { combineReducers } from 'redux';
//import Auth from './auth/reducers';
import Layout from './layout/reducers';
import Products from './products/product_reducers';
import Productsmore from './products/product_more_reducers';

////////aaaaaaaaaaaaa

/*export default (combineReducers({
    Layout,
}): any);*/


const rootReducer = combineReducers({
    Layout,
    Products,
    Productsmore,
});

export default rootReducer;